import axios from 'axios'
import Cookies from 'js-cookie'
import { getToken } from './user/auth.js'
import { MessageBox, Message } from 'element-ui'
import utils from "./utils"
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 30 * 1000 // request timeout
})
let hasMessageFunc = true;
try {
    hasMessageFunc = !!(hasMessageFunc && Message);
} catch (e) {
    hasMessageFunc = false;
}

service.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// request拦截器
service.interceptors.request.use(
    config => {
        if (config.headers['Content-Type'] === undefined) { config.headers['Content-Type'] = 'application/json;charset=UTF-8' }
        const token = getToken()
        if (token && !config.noToken && !(config.url.startsWith("http") && !config.url.startsWith(window.HIVUI_SETTING.url))) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        let __localeLang = Cookies.get("locale");
        if (__localeLang) {//设置语言包
            if (config.method == "post") {
                if (!config.params) {
                    config.params = {};
                }
                config.params.locale = __localeLang;
            } else {
                config.url = utils.StringFunc.setUrlValue(config.url, "locale", __localeLang);
            }
        }
        if (window.HIVUI_SETTING) {
            let site = window.HIVUI_SETTING.url
            //补全URL
            if (!config.url.toLowerCase().startsWith("http")) {
                if (config.url.startsWith("/"))
                    config.url = site + config.url;
                else
                    config.url = site + "/" + config.url;
            }



            if (window.HIVUI_SETTING.requestTimeout || window.HIVUI_SETTING.requestTimeout == 0) {
                config.timeout = window.HIVUI_SETTING.requestTimeout;
            }
            if (window.HIVUI_SETTING.projectName && config.url.indexOf('pn=') == -1 && !(config.params && config.params.pn)) {
                let pn = window.HIVUI_SETTING.projectName;
                if (config.url.indexOf('?') == -1)
                    config.url = config.url + "?pn=" + pn
                else
                    config.url = config.url + "&pn=" + pn
            }
            //岗位ID
            if (window.scpRequestData && window.scpRequestData.fbzid) {
                let fbzid = window.scpRequestData.fbzid;
                if (config.method == "post") {
                    if (!config.params) {
                        config.params = {};
                    }
                    config.params.fbzid = fbzid;
                } else {
                    config.url = utils.StringFunc.setUrlValue(config.url, "fbzid", fbzid);
                }
            }
        }

        // 如果接口需要签名, 则通过请求时,headers中传递sign参数true
        const iSSign = config.headers['sign'];
        if (iSSign || iSSign === undefined) {
            const timeStamp = new Date().getTime().toString().substr(0, 10);
            config.headers['timeStamp'] = timeStamp;
            //config.headers['signature'] = sign(config, nonce, timeStamp, store.getters.appSecret)
        }
        //开启下载流模式
        if (config.isDownload) {
            config.responseType = 'blob';
            delete config.isDownload;
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)
function MessageFunc(opt, res = {}) {//detailMsg\msg\message
    let messageCustomClass = utils.StringFunc.id(8);
    let __position = {
        top: 15,
        margin: 20,
        right: 15
    };
    let isMsgBox = window.HIVUI_SETTING.messageType == "msgBox";
    if (opt.showClose) {//关闭按钮多偏移
        __position.margin += 15;
        __position.right += 25;
    }
    if (isMsgBox) {
        __position.top = 0;
        __position.right = 0;
        __position.margin = 20;
    }
    let msgTpl = `<div class="zhcDetailMsg" style="max-height:200px;min-height: 20px;padding:3px 0;word-break:break-all;margin-right: ${__position.margin}px;overflow:auto;transition:all 0.3s;"><span>${res.msg || res.resmessage}</span><i class="el-icon-caret-right" title="${(window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_showErrorDetail) || '弹出报错详情'}" style="position: absolute;top:${__position.top}px;right:${__position.right}px;font-size:18px;color:#F56C6C;cursor:pointer;transition:all 0.3s;"></i></div>`;
    let msgVue = null;
    let isShowDetail = res.detailMsg && (res.detailMsg != opt.message);
    opt.customClass = messageCustomClass;
    if (isMsgBox) {
        if (isShowDetail) {
            opt.message = msgTpl;
        } else {
            opt.message = `<div style="max-height:200px;word-break:break-all;overflow:auto;">${opt.message}</div>`;
        }
        msgVue = MessageBox({
            ...{
                title: (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_msgTips) || "消息提示",
                confirmButtonText: (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_define) || '确定',
                dangerouslyUseHTMLString: true
            }, ...opt
        });
    } else {
        if (isShowDetail) {
            opt.message = msgTpl;
            opt.dangerouslyUseHTMLString = true;
        }
        msgVue = Message(opt);
    }
    if (isShowDetail) {
        let arrowBtn = document.querySelector("." + messageCustomClass + " .zhcDetailMsg>i");
        arrowBtn.onclick = () => {
            if (arrowBtn.previousSibling.className == "zhcDetailMsg") {
                arrowBtn.previousSibling.innerText = res.msg || res.resmessage;
                arrowBtn.previousSibling.className = "";
                arrowBtn.title = (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_showErrorDetail) || "弹出报错详情";
                arrowBtn.style.color = "#F56C6C";
                arrowBtn.style.transform = "rotate(0)";
            } else {
                arrowBtn.previousSibling.innerText = res.detailMsg;
                arrowBtn.previousSibling.className = "zhcDetailMsg";
                arrowBtn.title = (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_hideErrorDetail) || "收起报错详情";
                arrowBtn.style.color = "";
                arrowBtn.style.transform = "rotate(90deg)";
            }
        };
    }
}
function for401(isSkip) {
    // Alert('你已被登出，,点击确认按钮进入登录页', '登录超时', {
    //   confirmButtonText: '确定',
    //   callback: action => {
    //     window.location.href = window.appsettings.login;
    //   }
    // });
    if (window.customSysCofig.showMiniLogin && top.window.SysPage && top.window.SysPage.openMiniLogin) {
        top.window.SysPage.openMiniLogin();
    } else {
        let loginUrl = window.HIVUI_SETTING.loginUrl
        if (window.HIVUI_SETTING.isSingleLogin) {
            loginUrl = window.HIVUI_SETTING.singleLoginUrl;
            if (loginUrl.endsWith("=")) {
                let params = window.location.search.slice(1).split("&").filter(item => {
                    if (item.indexOf("ticket") == -1) {
                        return item
                    }
                });
                let currPageUrl = window.location.origin + window.location.pathname;
                if (window.HIVUI_SETTING.mainPageUrl.indexOf(currPageUrl) == -1) {
                    params.push("eapReturnUrl=" + currPageUrl);
                    currPageUrl = window.HIVUI_SETTING.mainPageUrl;
                }
                currPageUrl = currPageUrl.replace(/#\//g, "");
                loginUrl = loginUrl + currPageUrl + (params.length > 0 ? '?' : '') + params.join("&");
            }
        } else {
            if (!loginUrl.endsWith("#/")) {
                loginUrl += "#/";
            }
            loginUrl += "?eapReturnUrl=" + encodeURIComponent(window.location.href);
        }


        if ((top.window.mainPageInitErrorJump) || isSkip) {
            window.location.href = loginUrl;
        } else {
            hasMessageFunc && MessageBox.alert((window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_noPermissionTips) || '你已被登出,请重新登录', (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_timeOut) || '登录超时', {
                confirmButtonText: (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_Relist) || '重新登录',
                type: 'warning'
            }).then(() => {
                window.location.href = window.HIVUI_SETTING.miniLoginUrl || loginUrl;
            });
        }
    }
}

service.interceptors.response.use(response => {
    if (response.config.responseType == "arraybuffer") {
        return response;
    }
    if (response.config.normalResult)
        return response;
    const res = response.data;
    if (res.type == "application/octet-stream" || response.headers["content-type"] == "application/octet-stream") {
        return response;
    } else if (response.config.responseType == "blob") {
        //流数据抛错，将blob类型重新转换成json
        let fileReader = new FileReader();
        fileReader.readAsText(res, 'utf-8');
        fileReader.onload = function () {
            let __data = JSON.parse(fileReader.result);

            return hasMessageFunc && MessageFunc({
                message: __data.msg || __data.message || 'Error',
                type: 'error',
                showClose: true,
            }, res)
        }
        return Promise.reject(res)
    }
    if (response.status != "200" || (response.data.status && response.data.status != "200")) {
        let data = {}
        try {
            data = JSON.parse(response.config.data)
        } catch (ex) { }
        if (data.__isIntercept === false) {
            if (response.config.data) {
                try {
                    res.options = JSON.parse(response.config.data)
                } catch (e) {

                }
            }
            return Promise.reject(res)
        }
        hasMessageFunc && MessageFunc({
            message: res.msg || res.message || 'Error',
            type: 'error',
            showClose: true,
            duration: 5 * 1000
        }, res)
        if (res.status == 401 || (res.data && res.data.status == 401)) {
            for401(response.config.isSkip);
            return Promise.reject(error)
        }

        //return Promise.reject(new Error(res.message || 'Error'))
        return Promise.reject(res, JSON.parse(response.config.data || "{}"))
    } else {
        res.dataPack = res.dataPack || res.data
        if (res.detailMsg || res.msg) {
            hasMessageFunc && MessageFunc({
                message: res.detailMsg || res.msg || 'Error',
                type: (res.popupbox && res.popupbox.type) || 'success',
                showClose: true,
                duration: 5 * 1000
            }, res);
        }
        if (response.config.data) {
            try {
                res.options = JSON.parse(response.config.data)
            } catch (e) {
            }

        }
        return res
    }

}, error => {
    let res = error.response;
    if (!res) {
        hasMessageFunc && MessageFunc({
            message: (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_responseNull) || '后端请求返回出错，请联系开发人员',
            type: 'error',
            showClose: true,
            duration: 5 * 1000
        });
        return;
    }
    let data = {}
    try {
        data = JSON.parse(res.config.data)
    } catch (ex) { }
    if (data.__isIntercept === false) {
        return Promise.reject(error)
    }
    if (res.status == 401 || (res.data && res.data.status == 401)) {
        for401(res.config.isSkip);
        return Promise.reject(error)
    }
    console.log('err' + error)// for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
});

export default service


export function ajax(config) {
    const token = getToken();
    if (window.HIVUI_SETTING) {
        if (window.HIVUI_SETTING.requestTimeout || window.HIVUI_SETTING.requestTimeout == 0) {
            config.timeout = window.HIVUI_SETTING.requestTimeout;
        }
        if (window.HIVUI_SETTING.projectName) {
            let pn = window.HIVUI_SETTING.projectName;
            if (config.url.indexOf('?') == -1)
                config.url = config.url + "?pn=" + pn
            else
                config.url = config.url + "&pn=" + pn
        }
    }
    //创建XMLHttpRequest对象
    let url = config.url || "";
    let method = config.method || "GET";
    let data = config.data || {};
    let success = config.success;
    let async = config.async == undefined ? true : config.async;
    let fail = config.fail;
    var xhr = new XMLHttpRequest();
    //true表示异步
    xhr.open(method, url, async);
    if (config.timeout && async === true)
        xhr.timeout = config.timeout;
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    if (token && !config.noToken && !(url.startsWith("http") && !url.startsWith(window.HIVUI_SETTING.url))) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    }
    if (JSON.stringify(data) != "{}") {
        xhr.send(JSON.stringify(data));
    }
    else { xhr.send(); }
    if (async == false) {
        if (xhr.status == 200) {
            var res = JSON.parse(xhr.responseText);
            success && success.call(this, res);
        }
        else {
            var res = JSON.parse(xhr.responseText);
            hasMessageFunc && MessageFunc({
                message: res.msg || res.message || 'Error',
                type: 'error',
                showClose: true,
                duration: 5 * 1000
            }, res)
            if (res.status == 401 || (res.data && res.data.status == 401)) {
                if (window.customSysCofig.showMiniLogin && top.window.SysPage && top.window.SysPage.openMiniLogin) {
                    top.window.SysPage.openMiniLogin();
                } else {
                    hasMessageFunc && MessageBox.alert((window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_noPermissionTips) || '你已被登出,请重新登录', (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_timeOut) || '登录超时', {
                        confirmButtonText: (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_Relist) || '重新登录',
                        type: 'warning'
                    }).then(() => {
                        let loginUrl = window.HIVUI_SETTING.loginUrl
                        if (loginUrl.endsWith("="))
                            loginUrl = loginUrl + window.location.href;
                        window.location.href = window.HIVUI_SETTING.miniLoginUrl || loginUrl
                    })
                }
            }
            else
                fail && fail.call(this, xhr.responseText);
        }
    }
    else {
        xhr.onreadystatechange = function () {
            // readyState == 4说明请求已完成

            if (xhr.readyState == 4) {
                if (xhr.status == 200 || xhr.status == "200") {
                    var res = JSON.parse(xhr.responseText);
                    //responseText：从服务器获得数据           
                    success && success.call(this, res);
                }
                else {
                    var res = JSON.parse(xhr.responseText);
                    hasMessageFunc && MessageFunc({
                        message: res.msg || res.message || 'Error',
                        type: 'error',
                        showClose: true,
                        duration: 5 * 1000
                    }, res)
                    if (res.status == 401 || (res.data && res.data.status == 401)) {
                        if (window.customSysCofig.showMiniLogin && top.window.SysPage && top.window.SysPage.openMiniLogin) {
                            top.window.SysPage.openMiniLogin();
                        } else {
                            hasMessageFunc && MessageBox.alert((window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_noPermissionTips) || '你已被登出,请重新登录', (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_timeOut) || '登录超时', {
                                confirmButtonText: (window.GLOBAL_LANG_TPL && window.GLOBAL_LANG_TPL.hivuiMain_eap_Relist) || '重新登录',
                                type: 'warning'
                            }).then(() => {
                                let loginUrl = window.HIVUI_SETTING.loginUrl
                                if (loginUrl.endsWith("="))
                                    loginUrl = loginUrl + window.location.href;
                                window.location.href = window.HIVUI_SETTING.miniLoginUrl || loginUrl
                            })
                        }
                    }
                    else
                        fail && fail.call(this, xhr.responseText);
                }
            }

        };
    }
}